@font-face {
  font-family: "SaoTorpes";
  src: local("SaoTorpes"), url("./fonts/SaoTorpes.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "Loved By The King";
  src: local("Loved By The King"),
    url("./fonts/LovedbytheKing-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Starborn";
  src: local("Starborn"), url("./fonts/Starborn.ttf") format("truetype");
  font-weight: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;1,300&display=swap");

:root {
  --no-of-slides: 9;
  --slides-in-view: 5;
  --slide-width: 400px;
  --margin: 40px;
  --slide-width-w-margin: 410px;
  --custom-width: 200px;
  --slide-height: 200px;
  --iteration-time: 60s;
  --base-light-color: rgba(173, 150, 128, 0.3);
  --base-base-color: #fff;
  --base-shadow-color: #947b67;
}

* {
  margin: 0 !important;
  padding: 0;
  box-sizing: border-box;
}

.carouselGif {
  padding-top: 20px;
}

.carouselGif img {
  width: 120px;
  border: 4px dashed #fff;
  border-radius: 70px;
}

.doodle {
  position: fixed !important;
  z-index: -1 !important;
}

.Toastify__toast-body {
  font-size: 2rem;
  font-weight: 500;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto Mono", sans-serif;
  color: #252525;
  font-weight: 400;
  min-height: 100vh;
  padding: 0px;
  z-index: 100;
  background-image: url("powderbg.png");
  background-size: cover; /* optional */
  background-attachment: fixed;
}

.gif-container-left {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000; /* adjust z-index to ensure it's on top of other elements */
}

.gif-container-right {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000; /* adjust z-index to ensure it's on top of other elements */
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.gif-image {
  width: 300px; /* adjust width and height to your liking */
  height: 300px;
  border-radius: 50%; /* make the image circular */
  object-fit: cover; /* ensure the image is scaled to fit within the circular shape */
  padding: 50px;
  animation: wiggle 3s ease-in-out infinite; /* add the wiggling animation */
  transition: transform 0.5s;
}

.gif-image:hover {
  transform: scale(0.5); /* enlarge the image by 20% on hover */
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#kewpeeBanner {
  display: flex;
  width: 500px;
  margin: 0 auto !important;
}

#banner-section {
  margin-top: 100px !important;
  display: flex;
  justify-content: center;
}

.ball {
  position: fixed;
  border-radius: 100%;
  opacity: 0.7;
  z-index: -1;
}

.hide {
  display: none !important;
}

.loader {
  position: fixed;
  color: #fff;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

#main-container {
  padding-top: 50px;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 3000px !important;
  background-color: transparent;
}

.btn {
  color: inherit;
  font-family: inherit;
  border: none;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: #edc84b;
  padding: 1.4rem 3.2rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn:hover {
  background-color: #e9bb24;
}

/**************************** TOP HEADER ****************************/

.header {
  align-self: stretch;
  margin-bottom: 30px;
  position: relative;
}

.headerInner::before {
  content: ""; /* create a pseudo-element for the sparkling effect */
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    farthest-corner at 50% 50%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  background-size: 10px 10px;
  animation: sparkle 0.2s ease-in-out infinite;
  pointer-events: none; /* prevent the sparkling effect from interfering with clicks */
}

@keyframes sparkle {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.header h3 {
  text-align: center;
  font-family: SaoTorpes;
  font-style: normal !important;
  font-size: 2rem;
}

.header h1 {
  /* Non-accessible color */
  margin-bottom: 30px !important;
  color: var(--base-base-color) !important;
  text-shadow: -4px 4px 2px var(--base-shadow-color);
  /* color: #af8602; */
  font-family: Starborn, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 8rem;
  font-weight: 800;
  letter-spacing: 3px;
  position: relative;
  width: 100%;
  display: block;
}

.header h1::before {
  left: 0;
}

.header h1::after {
  right: 0;
}

.contractClip {
  align-items: center;
  background-color: #fff;
  color: #000 !important;
  border: 2px solid #000;
  box-shadow: -5px 6px 0 0 #000;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 1.5rem;
  justify-content: center;
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 15px !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.contractCopy {
  font-size: 16px;
  margin-top: var(--v-small);
  position: relative;
  z-index: 2;
}

.contractCopy .clipboard {
  align-items: center;
  background-color: var(--primary);
  border: 2px solid var(--negative);
  border-radius: 100px;
  box-shadow: -5px 6px 0 0 var(--negative);
  font-family: var(--heading);
  font-size: var(--btn-large);
  justify-content: center;
  padding: calc(var(--gutter) / 2);
  transition: all 0.2s ease-in-out;
}

.socialsHeader {
  color: #beefe8;
  text-align: center;
}

.socialsHeader h4 {
  display: inline-block;
  padding: 1rem 2rem;
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: 300;
  color: #08415c;
}

.social-icon {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon a {
  width: 50px;
  height: 50px;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px !important;
  box-shadow: -3px 3px 0 0 #fff;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #fff;
}
.social-icon a::before {
  content: "";
  width: 35px;
  height: 35px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

/**************************** TOP HEADER ****************************/

/**************************** MAIN BANNER ****************************/

.mainbanner {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 20px !important;
}

.mainbanner h1 {
  text-transform: lowercase !important;
  font-size: 5rem;
  text-shadow: -6px 6px 2px #ff3366;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.mainbanner::before,
.mainbanner::after {
  content: "";
  padding-top: 50px;
  width: 50px;
  height: 100px;
}

.mainbanner span,
h1 {
  color: #db93a5 !important;
}

.mainbanner span,
h3 {
  color: #08415c !important;
  margin-bottom: 10px !important;
}

.mainBannerCols {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo {
  animation: updown 3s linear infinite;
  width: 400px;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

/**************************** MAIN BANNER ****************************/

/**************************** ABOUT ****************************/

#about-section {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 400px;
  padding-right: 400px;
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

#about-section h1 {
  text-transform: lowercase !important;
  font-size: 5rem;
  text-shadow: -6px 6px 2px #ff3366;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

#about-section p {
  text-align: left;
  color: #08415c;
  font-size: 2.5rem;
  text-align: center;
  margin-top: 20px !important;
}

/**************************** ABOUT ****************************/

/**************************** CAROUSEL ****************************/

#carousel-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: 30rem;
  background-color: rgba(0, 0, 0, 0.88);
  border: solid 10px #fff;
  border-radius: 10px;
  position: relative;
  top: 40%;
  left: 28%;
  z-index: 10;
  overflow-y: hidden;
}

#carousel-desc h1,
#carousel-desc p {
  color: #fff !important;
}

#carousel-desc h1 {
  font-family: StarBorn, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-transform: uppercase;
  font-size: 5rem;
}

#carousel-desc p {
  margin-top: 20px !important;
  font-size: 2rem;
  width: 50rem; /* set the maximum width */
  word-wrap: break-word;
  text-align: center;
}

#carousel-section {
  display: flex;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
}

.carousel-container {
  margin-top: -150px !important;
}

.carousel-container {
  overflow: hidden;
  width: calc(2 * var(--no-of-slides));
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.carousel-container2 {
  overflow: hidden;
  width: calc(2 * var(--no-of-slides));
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
}

.carousel-container h1 {
  font-family: saotorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: #db93a5 !important;
  text-shadow: -3px 3px 1px #ff3366;
  font-size: 4rem;
  text-align: center;
}

.carousel-track {
  width: calc(400px * 410px);
  animation: slide var(--iteration-time) linear infinite;
  display: flex;

  &:hover {
    animation-play-state: paused;
  }
}

.carousel-track img,
.carousel-track2 img {
  margin: 0;
  padding: 3px !important;
  width: 400px !important;
  height: 400px;
}

.carousel-track2 {
  width: calc(400px * 410px);
  animation: slide2 var(--iteration-time) linear infinite;
  display: flex;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(var(--slide-width-w-margin) * var(--no-of-slides) * -1)
    );
  }
}

@keyframes slide2 {
  100% {
    transform: translateX(0);
  }
  0% {
    transform: translateX(
      calc(var(--slide-width-w-margin) * var(--no-of-slides) * -1)
    );
  }
}

/**************************** CAROUSEL ****************************/

/**************************** CREATE MEMES ****************************/

#meme-section {
  display: flex;
  justify-content: center;
  margin-top: 50px !important;
  padding: 50px 0 !important;
  overflow: hidden;
}

#meme-section-b {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 50px !important;
  padding: 50px 0 !important;
}

.memeItem {
  display: flex;
  justify-content: center;
  animation: wiggle 3s ease-in-out infinite;
}

.meme-decorator {
  display: flex;
  justify-content: center;
  width: 200px;
  border: 5px solid #fff;
}

#create-section {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  margin-top: 100px !important;
  margin-bottom: 20px !important;
}

#create-section h1 {
  text-transform: lowercase !important;
  font-size: 5rem;
  text-shadow: -6px 6px 2px #ff3366;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  margin-bottom: 30px !important;
}

#create-section p {
  text-align: left;
  color: #08415c;
  font-size: 2rem;
  text-align: center;
}

#create-arrow {
  width: 150px;
  display: flex;
  margin: 0 auto !important;
}

#video-wrapper {
  padding-left: 200px;
  padding-right: 200px;
}

#pop-video {
  border: double 2.5em transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #fcd4b5, #fcd4b5);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin: 0 auto !important;
  margin-top: 50px !important;
  display: flex;
  aspect-ratio: 16 / 9;
  width: 100%;
}

#base-display {
  width: 400px;
  /*border-radius: 50px;*/
  border: double 1em transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, #fcd4b5, #fcd4b5);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#base-display:hover {
  border: double 1em transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to bottom right, pink, #ff3366);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

#meme-showcase {
  width: 400px;
  background-color: #fcd4b5;
  border: solid 5vmin #eee;
  border-bottom-color: #fff;
  border-left-color: #eee;
  border-radius: 2px;
  border-right-color: #eee;
  border-top-color: #ddd;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) inset,
    0 5px 10px 5px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  display: inline-block;
  margin: 3vh 3vw !important;
  height: 500px;
  padding: 1vmin;
  position: relative;
  text-align: center;
  &:before {
    border-radius: 2px;
    bottom: -2vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
    content: "";
    left: -2vmin;
    position: absolute;
    right: -2vmin;
    top: -2vmin;
  }
  &:after {
    border-radius: 2px;
    bottom: -2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    content: "";
    left: -2.5vmin;
    position: absolute;
    right: -2.5vmin;
    top: -2.5vmin;
  }
}

/**************************** CREATE MEMES ****************************/

/**************************** DIVIDERS ****************************/

.skew-cc {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0px;
  background: linear-gradient(to right bottom, transparent 49%, #ffdfc7 50%);
}

.skew-c {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0px;
  background: linear-gradient(
    to left bottom,
    #ffdfc7 49%,
    var(--base-light-color) 50%
  );
}

/**************************** DIVIDERS ****************************/

/**************************** HOW TO BUY SECTION ****************************/

#buy-btn {
  font-size: 2rem;
  font-family: starborn, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: #fff;
  box-shadow: -5px 6px 0 0 #ff3366;
  border: 2px solid #000;
  border-radius: 20px;
  background-color: #db93a5;
  display: flex;
  justify-content: center;
  margin: 0 auto !important;
  width: 200px;
}

.itemBtn {
  font-size: 2rem;
  font-family: starborn, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: #fff;
  box-shadow: -5px 6px 0 0 #ff3366;
  border-radius: 20px;
  background-color: #db93a5;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  text-align: left !important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  width: 200px;
  text-decoration: none;
}

.buy-guide {
  margin-bottom: 100px !important;
  padding-top: 5rem;
  position: relative;
}

.buy-guide::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("cocainebg.png");
  background-size: cover;
  opacity: 0.7; /* adjust the value to control transparency */
}

.buy-guide h3 {
  text-align: center;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-size: 3rem;
  color: #fff !important;
  text-shadow: 0px 0px 5px #000, 0px 0px 5px #000, 0px 0px 5px #000,
    0px 0px 5px #fff;
}

.buy-guide p {
  text-shadow: 0px 0px 5px #000, 0px 0px 5px #000, 0px 0px 5px #000;
  font-size: 2rem !important;
}

.buy-guide h1 {
  font-family: "Comic Sans MS", cursive;
  color: #fff !important;

  text-transform: uppercase;
  text-align: center;
  font-size: 5.2rem;
  font-weight: 800;
  letter-spacing: 3px;
  position: relative;
  width: 100%;
  display: block;
}

#buy-list {
  padding: 10px 300px;
  margin-top: 20px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

.guideItem {
  width: 100%;
}

#buy-list li {
  margin: 50px 0px !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

#buy-list li span {
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
  padding: 3px 10px;
  margin-right: 20px !important;
  font-size: 2rem;
  flex-shrink: 0;
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-transform: translateY(15%);
  transform: translateY(15%);
}

#buy-list li h3 {
  color: #fff !important;
  text-transform: uppercase;
  text-align: left;
  margin: 0 !important;
  font-family: SaoTorpes, Haettenschweiler, "Arial Narrow Bold", sans-serif !important;
}

#buy-list li p {
  text-align: left;
  color: #fff;
  font-size: 2rem;
}

/**************************** HOW TO BUY SECTION ****************************/

/**************************** FOOTER ****************************/

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
}

/**************************** FOOTER ****************************/

/**************************** RESPONSIVENESS ****************************/
@media screen and (max-width: 1150px) {
  #about-section {
    padding-left: 50px;
    padding-right: 50px;
  }

  #base-display {
    width: 300px;
  }

  #meme-showcase {
    margin: 3vh auto !important;
    display: flex;
    width: 320px;
    height: 360px;
    padding: 1vmin;
  }

  #video-wrapper {
    padding-left: 100px;
    padding-right: 100px;
  }

  #buy-list {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media screen and (max-width: 950px) {
  #logo {
    width: 300px;
  }

  #carousel-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    top: 40%;
    left: 5%;
  }
}

@media screen and (max-width: 500px) {
  .carouselGif {
    padding-top: 5px;
  }

  .carouselGif img {
    width: 80px;
  }

  .meme-decorator {
    width: 150px;
  }

  .gif-image {
    width: 100px; /* adjust width and height to your liking */
    height: 100px;
    padding: 10px;
  }

  .buy-guide::before {
    background-position: 50% 30%; /* adjust the values to reposition the image */
  }

  .carousel-track {
    width: calc(240px * 250px);
  }

  .carousel-container {
    margin-top: -200px !important;
  }

  .carousel-track2 {
    width: calc(240px * 250px);
  }

  .carousel-track img,
  .carousel-track2 img {
    margin: 0;
    padding: 3px !important;
    width: 250px !important;
    height: 250px !important;
  }

  #carousel-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 22rem;
    border-radius: 50px;
    border: solid 7px #fff;
    box-shadow: -5px 6px 8px 0 #000;
    top: 30%;
    left: 9%;
  }

  #carousel-desc h1 {
    font-size: 4rem;
  }

  #carousel-desc p {
    margin-top: 5px !important;
    font-size: 1.5rem;
    width: 25rem; /* set the maximum width */
    word-wrap: break-word;
    text-align: center;
  }

  #video-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  #pop-video {
    border: double 0.5em transparent;
  }

  .mainbanner h1 {
    font-size: 3.2rem;
  }

  .mainbanner {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  #logo-col {
    margin-bottom: 20px !important;
    padding-bottom: 10px !important;
  }

  #about-display {
    width: 300px;
  }

  #about-section {
    margin-top: 70px !important;
  }

  #about-section h1 {
    margin-top: 50px !important;
    font-size: 3.5rem;
  }

  #about-section p {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  #create-section p {
    font-size: 1.5rem;
  }

  .carousel-container h1 {
    font-size: 3rem;
  }

  #carousel-section {
    padding-left: 0px;
    padding-right: 0px;
  }

  #create-section {
    padding-left: 10px;
    padding-right: 10px;
  }

  #create-section h1 {
    font-size: 3rem;
  }

  #base-display {
    width: 300px;
  }

  #meme-showcase {
    margin: 3vh auto !important;
    display: flex;
    width: 300px;
    height: 45vh;
    padding: 5vmin;
  }

  #about-section {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  #about-section p {
    font-size: 2rem;
  }

  .buy-guide {
    margin-top: 100px !important;
  }

  .buy-guide h1 {
    font-size: 3.5rem;
  }

  .buy-guide h3 {
    font-size: 2.5rem;
  }

  #buy-list {
    padding: 10px 20px !important;
    margin-top: 20px;
  }

  #buy-list li p {
    font-size: 1.5rem;
  }

  #logo {
    padding-top: 20px;
    width: 200px;
  }

  .header h1 {
    font-size: 5rem;
  }

  .header h1::before,
  .header h1::after {
    width: 2rem;
  }

  .social-icon a {
    width: 40px;
    height: 40px;
  }

  .Toastify__toast-body {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center !important;
  }
}

/**************************** RESPONSIVENESS ****************************/

/**************************** TICKER ****************************/

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: relative;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 7rem;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  background-color: #fff;
  box-sizing: content-box;

  .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-top: 10px;
    padding-left: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;

    .ticker__item {
      display: inline-block;
      font-weight: 900;
      font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
      padding: 0 2rem;
      font-size: 4rem;
      color: #000;
      margin-right: 20px;
    }
  }
}

.ticker-inner {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
}

/**************************** TICKER ****************************/
